








































/*eslint-disable */
import Vue from "vue";
export default Vue.extend({
  name: "InternalMenu",
  props: ["menuItems"],

  methods: {
    validPermission(permission: any) {
      if (permission === "all") return true;
      let user = localStorage.getItem("user") as any;
      if (user) user = JSON.parse(user) as any;
      if (!user) return false;
      if (user.admin) return true;
      const permissions = user?.group?.permissions?.map((d: any) => d.id);
      if (!permissions || !permissions?.length) return false;
      if (permissions.includes(permission) || (user && user.admin)) return true;
      else return false;
    },
  },
});
